// import request from '@/utils/request'

import request from '@/axios'



// @Summary 用户登录
// @Produce  application/json
// @Param data body {account_name:"string",password:"string"}
// @Router /base/login [post]
export async function login(data: any) {
  const res = await request.post('/admin/login', data)
  return res?.data
}
// 退出
export async function logout() {
  const res = await request.post('/admin/logout')
  return res?.data
}


/**
 * 获取token
 */

// const pending: any = {
//   token: ''
// }

// export const getToken = () => {
//   if (pending.token) return pending.token
//   pending.token = request.get('/home', { responseType: 'text' }).then(({ data }:any) => {
//     delete pending.token
//     if (/name="?token"?\s*content="?([^>\s"]+)"?/.test(data)) return RegExp.$1
//     return ''
//   }).catch(() => {
//     delete pending.token
//   })
//   return pending.token
// }

export const getToken = async () => {
  const res = await request.get('/admin/token')
  return res?.data
}