import request from '@/axios'

// 获取内容列表筛选条件
export async function getFilterList(data:any) {
  const res = await request.get('/admin/product_power/land_parcel_attribute/filter', data)
  return res?.data
}

// 获取列表
export async function getList(data:any) {
  const res = await request.post('/admin/product_power/land_parcel_attribute/calculation', data)
  return res?.data
}

export default {
  getFilterList,
  getList
}