import request from '@/axios'

// 获取内容列表筛选条件
export async function getMulFilter(data:any) {
  const res = await request.get('/admin/product_power/designated_population/filter', data)
  return res?.data
}

// 获取列表
export async function getList(data:any) {
  const res = await request.post('/admin/product_power/designated_population/calculation', data)
  return res?.data
}

export default {
  getMulFilter,
  getList
}